var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"qb-staging-release@2024-10-14T19:49:34.702Z"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import {
  extraErrorDataIntegration,
  debugIntegration,
  httpClientIntegration,
  BrowserOptions,
  replayIntegration,
} from '@sentry/react';
import { FrontendLogicError } from '@qb/frontend/utils/FrontendLogicError';
import { isApiBusinessLogicError } from '@qb/httpRequest/utils';

const isSentryEnabled =
  process.env.ENV_NAME === 'production' || process.env.ENV_NAME === 'staging';

export const getSentryEnvInfo = (debug: boolean): Partial<BrowserOptions> => ({
  environment: process.env.ENV_NAME || 'development',
  enabled: isSentryEnabled,
  release: process.env.SENTRY_RELEASE,
  dsn: process.env.SENTRY_DSN,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug,
});

export const getBaseSentryClientConfig = (
  debug: boolean,
): Partial<BrowserOptions> => ({
  ...getSentryEnvInfo(debug),
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // No need to replay non-error sessions.
  replaysSessionSampleRate: 0,
  // Record all sessions that have had an error.
  replaysOnErrorSampleRate: process.env.ENV_NAME === 'production' ? 1 : 0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    httpClientIntegration(),
    /*
     * Disabled for now, it's throwing lots of events that are not so relevant.
     * Consider re-enabling it in the future.
     * */
    // captureConsoleIntegration({
    //   levels: ['error'],
    // }),
    extraErrorDataIntegration({
      depth: 100,
      captureErrorCause: true,
    }),
    replayIntegration({
      blockAllMedia: false,
      maskAllText: false,
      maskAllInputs: true,
    }),
    ...(debug ? [debugIntegration()] : []),
  ],
  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,
  attachStacktrace: true,
  normalizeDepth: 100,

  beforeSend: (event, hint) => {
    const originalException = hint.originalException;

    if (originalException instanceof FrontendLogicError) {
      event.level = 'info';
    }

    if (isApiBusinessLogicError(originalException)) {
      if ([401, 403, 404].includes(originalException.status)) {
        event.level = 'info';
      }
    }

    return event;
  },
});
